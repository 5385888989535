import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image, Table, Row, Attributes, Expander, Tabs, InfoIcon, WarningIcon } from '../components';
import { getAccountPropertiesCurl, getAccountPropertiesJava, getAccountPropertiesJavascript, getAccountPropertiesNode, getAccountPropertiesPerl, getAccountPropertiesPython, getAccountPropertiesPhp, getAccountPropertiesRuby, getAccountPropertiesGo, getAccountPropertiesC, getAccountPropertiesVB, getAccountPropertiesGroovy, getAccountPropertiesObjectiveC, getAccountPropertiesSwift, storeAccountPropertiesRaw, storeAccountPropertiesCurl, storeAccountPropertiesJava, storeAccountPropertiesJavascript, storeAccountPropertiesNode, storeAccountPropertiesPerl, storeAccountPropertiesPython, storeAccountPropertiesPhp, storeAccountPropertiesRuby, storeAccountPropertiesGo, storeAccountPropertiesC, storeAccountPropertiesVB, storeAccountPropertiesGroovy, storeAccountPropertiesObjectiveC, storeAccountPropertiesSwift, deleteAccountPropertiesCurl, deleteAccountPropertiesJava, deleteAccountPropertiesJavascript, deleteAccountPropertiesNode, deleteAccountPropertiesPerl, deleteAccountPropertiesPython, deleteAccountPropertiesPhp, deleteAccountPropertiesRuby, deleteAccountPropertiesGo, deleteAccountPropertiesC, deleteAccountPropertiesVB, deleteAccountPropertiesGroovy, deleteAccountPropertiesObjectiveC, deleteAccountPropertiesSwift } from 'requestExamples/user-accounts';
export const endpoints = [{
  param: 'GET',
  value: '/nodes/account-property/{account}/{domain}/{key}',
  link: '#retrieve-properties'
}, {
  param: 'PUT',
  value: '/nodes/account-property/{account}/{domain}/{key}',
  link: '#store-properties'
}, {
  param: 'DELETE',
  value: '/nodes/account-property/{account}/{domain}/{key}',
  link: '#delete-properties'
}];
export const baseGetUrl = [{
  param: 'GET',
  value: '/nodes/account-property/{account}/{domain}/{key}'
}];
export const baseStoreUrl = [{
  param: 'PUT',
  value: '/nodes/account-property/{account}/{domain}/{key}'
}];
export const baseDeleteUrl = [{
  param: 'DELETE',
  value: '/nodes/account-property/{account}/{domain}/{key}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  baseGetUrl,
  baseStoreUrl,
  baseDeleteUrl,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "managing-account-properties"
    }}>{`Managing account properties`}</h1>
    <Row equalWidth mdxType="Row">
	<div>
		<p>
			You are also able to do a similar property management on an account level as you do on a user level.
			<br />
			<br />
			For this, you'll need to use the /nodes/account-property request path to be able to get, store and delete properties
			for the logged in user for the current session.
			<br />
			<br />
			Information is organized the same way it is on{' '}
			<a href="/api/user-accounts/managing-user-properties">managing user properties</a>
			, as an object with the properties domain, key and value.
			<br />
			<br />
		</p>
	</div>
	<Table title="endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <hr></hr>
    <br />
    <Expander title="Properties" mdxType="Expander">
	<Attributes items={[{
        name: 'domain',
        type: 'string (optional)',
        description: 'It is essentially a flat namespace and acts as a bucket to store one or more keys. Must be unique within the account.'
      }, {
        name: 'key',
        type: 'string (optional)',
        description: 'Used to identify its corresponding value. Each key can only have one corresponding value. Must be unique within the domain.'
      }, {
        name: 'value',
        type: 'string (optional)',
        description: 'The information to be stored. Identifiable by a unique key'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />

    <h2 {...{
      "id": "retrieve-properties"
    }}>{`Retrieve properties`}</h2>
    <hr></hr>
    <Row mdxType="Row">
	<div>
		<p>
			You can use a <span style={{
            "color": "#3c76e9"
          }}>GET</span> request to retrieve information related to an account
			using the account name, domain and key.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			You can also retrieve all the keys from a domain not specifying the key parameter and using the "?children"
			query param after the domain.
			<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			<strong>
				You won't be able to see the properties values if you don't specify the query param "?eprop=true".
			</strong>
		</p>
	</div>
	<Table title="Get properties" rows={baseGetUrl} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account',
      type: 'string',
      description: "The name of the user's account you want to store information into."
    }, {
      name: 'domain',
      type: 'string',
      description: 'The domain you want to retrieve the information from.'
    }, {
      name: 'key',
      type: 'string',
      description: 'Identify the value you want to get by its key.'
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getAccountPropertiesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getAccountPropertiesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getAccountPropertiesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getAccountPropertiesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getAccountPropertiesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: getAccountPropertiesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: getAccountPropertiesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getAccountPropertiesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getAccountPropertiesGo
    }, {
      tabTitle: 'C#',
      tabInfo: getAccountPropertiesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getAccountPropertiesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getAccountPropertiesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getAccountPropertiesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getAccountPropertiesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`By default, it returns the account-property object data. As stated above, you'll need to specify the query param "?eprop=true" if you want the value of the key to be present on the response.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "test.key",
        "createdOn": "2020-06-22T12:34:13-05:00",
        "modifiedOn": "2020-06-22T12:34:13-05:00",
        "uri": "/nodes/account-property/SmartVault account/test.domain/test.key",
        "your_permissions": 64
    }
}

// Querying with the eprop param will result in an output like:

{
    "error": {
        "success": true
    },
    "message": {
        "name": "test.key",
        "createdOn": "2020-06-22T12:47:20-05:00",
        "modifiedOn": "2020-06-22T12:47:20-05:00",
        "uri": "/nodes/account-property/SmartVault account/test.domain/test.key",
        "your_permissions": 64,
        "propertyExProperties": {
            "domain": "test.domain",
            "key": "test.key",
            "value": "Example value for test.key on test.domain"
        }
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Not specifying the key and setting the children query param, would return all the keys stored under a certain domain.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Example of a call without specifying the key param /account-property/SmartVault account/test.domain?children=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "test.domain",
        "createdOn": "2020-06-22T12:50:01-05:00",
        "modifiedOn": "2020-06-22T12:50:01-05:00",
        "uri": "/nodes/account-property/SmartVault account/test.domain",
        "your_permissions": 64,
        "total_children": 1,
        "children": [
            {
                "name": "test.key",
                "createdOn": "2020-06-22T12:50:01-05:00",
                "modifiedOn": "2020-06-22T12:50:01-05:00",
                "uri": "/nodes/account-property/SmartVault account/test.domain/test.key",
                "your_permissions": 64,
                "total_children": 0
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "store-properties"
    }}>{`Store properties`}</h2>
    <hr></hr>
    <Row mdxType="Row">
	<div>
		<p>
			You can store properties for a logged in user by placing a PUT request to
			/nodes/account-property/account/domain/key and specifying the value for the key on the request body.
		</p>
	</div>
	<Table title="Store properties" rows={baseStoreUrl} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account',
      type: 'string',
      description: "The name of the user's account you want to store information into."
    }, {
      name: 'domain',
      type: 'string',
      description: 'The domain you want to set the information to.'
    }, {
      name: 'key',
      type: 'string',
      description: 'The key to identify the information within the domain.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'value',
      type: 'string',
      description: "The value for the key property you're creating."
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "value": "Example text to be stored on the key."
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: storeAccountPropertiesRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: storeAccountPropertiesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: storeAccountPropertiesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: storeAccountPropertiesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: storeAccountPropertiesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: storeAccountPropertiesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: storeAccountPropertiesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: storeAccountPropertiesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: storeAccountPropertiesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: storeAccountPropertiesGo
    }, {
      tabTitle: 'C#',
      tabInfo: storeAccountPropertiesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: storeAccountPropertiesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: storeAccountPropertiesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: storeAccountPropertiesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: storeAccountPropertiesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a default node object.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "test.key",
        "createdOn": "2020-06-22T12:30:54-05:00",
        "modifiedOn": "2020-06-22T12:30:54-05:00",
        "uri": "/nodes/account-property/SmartVault account/test.domain/test.key",
        "your_permissions": 64
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Will return an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if either the account or domain from path or value from body parameters are not present in the request.`}</p>
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "delete-properties"
    }}>{`Delete properties`}</h2>
    <hr></hr>
    <Row mdxType="Row">
	<div>
		<p>
			You are also able to delete some information saved in a domain using a delete request.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			You cannot delete a domain, only the keys in the domains.
			<br />
			<br />
		</p>
	</div>
	<Table title="Delete properties" rows={baseDeleteUrl} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account',
      type: 'string',
      description: "The name of the user's account you want to store information into."
    }, {
      name: 'domain',
      type: 'string',
      description: 'The domain you want to retrieve the information from.'
    }, {
      name: 'key',
      type: 'string',
      description: 'Identify the value you want to get by its key.'
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: deleteAccountPropertiesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: deleteAccountPropertiesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: deleteAccountPropertiesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: deleteAccountPropertiesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: deleteAccountPropertiesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: deleteAccountPropertiesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: deleteAccountPropertiesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: deleteAccountPropertiesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: deleteAccountPropertiesGo
    }, {
      tabTitle: 'C#',
      tabInfo: deleteAccountPropertiesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: deleteAccountPropertiesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: deleteAccountPropertiesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: deleteAccountPropertiesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: deleteAccountPropertiesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a default object if deletion goes well.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {}
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the key doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "44242db2-5278-473c-883f-bb4cdb23f14c",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      